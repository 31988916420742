import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Helmet } from "react-helmet";
import { Keywords } from "../components/constants";
import Layout from "../components/layout";
import JbltxIconWhite from "../images/jbltx-tag-white.png";

const AboutPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>About | JBLTX</title>
        <meta name="author" content="Mickael Bonfill" />
        <meta name="description" content="Information about Mickael  Bonfill aka JBLTX" />
        <meta name="keywords" content={Keywords.join(", ")} />
      </Helmet>
      <div className="main-content">
          <div className="about-hero">
            <StaticImage className="about-hero-image" src="http://s3.amazonaws.com/nft.jbltx.com/wild-mindz/artworks/36.png" alt="Hero Banner" />
          </div>
          <article className="about-textual-content">
            <h1 className="primary-title">
                <img className="about-title-logo" src={JbltxIconWhite} alt="JBLTX Title Logo" />
            </h1>

            <p className="justified-text">
            JBLTX, or <a rel="noreferrer noopener" href="https://linkedin.com/in/mickaelbonfill" target={"_blank"} >Mickael Bonfill</a> is a Software Engineer and Artist living in Montreal, Canada. He studied in Art School in France and he is a self-taught programmer. Since then he is still exploring how to create experiences in the digital world.
            </p>

            <p className="justified-text">
            He founded PayetaProd Studio in 2012 which was his first big project. The origanization has released a short animated movie <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=fzrfrXhE-w4" target={"_blank"} >available on Youtube</a>.
            </p>

            <p className="justified-text">
            In 2016 he moved from France to Canada, and is now employed with <a rel="noreferrer noopener" href="https://unity.com/" target={"_blank"} >Unity Technologies</a> as a Senior Software Engineer. But he continues to explore Digital Art in personal and professional projects.
            </p>

            <p className="justified-text">
            Lately he has started to make his own art which is an exploration of the connection between math, art. He is a fan of street art and retro wave art style.
            </p>

            <p className="justified-text">
            To be productive, he listens to synthwave/dark-synth music and works out a lot.
            </p>

            <h2 className="secondary-title">
                Collections
            </h2>

            <p className="justified-text">
            To ensure the authenticity of my work, please only use the following links to explore my collections. Feel free to reach out if you are unsure on how to buy or if you have any other questions.
            </p>

            <ul>
                <li>
                    <a rel="noreferrer noopener" href="https://opensea.io/collection/wild-mindz" target={"_blank"} >Wild Mindz (OpenSea)</a>
                </li>
                <li>
                    <a rel="noreferrer noopener" href="https://opensea.io/collection/neon-exploration" target={"_blank"} >Neon Exploration (OpenSea)</a>
                </li>
            </ul>

          </article>
      </div>
    </Layout>
  );
};

export default AboutPage;
